import '../styles/globals.css'
import type { ReactElement, ReactNode } from 'react'
import { UserProvider } from '@auth0/nextjs-auth0'
// import EnvironmentSelectionModal from '@/components/molecules/modal/environmentSelection'
// import { useRouter } from 'next/router'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page)
  const { user } = pageProps
  // const router = useRouter()

  return (
    <UserProvider user={user}>
      {/* {router.pathname !== 'landing' && <EnvironmentSelectionModal />} */}
      {getLayout(<Component {...pageProps} />)}
    </UserProvider>
  )
}

export default MyApp
